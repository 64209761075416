import React, { useEffect } from 'react';
import { goodsObject } from './data/goodsObject';
import { countryArr } from './data/country';
import Calculator, { validateEmail } from './tool/data';
import './App.css';


const tg = "lm099bot";
const payHost = "https://pay.lm99.pro/";


function App() {

  const [category, setCategory] = React.useState('');
  const [goods, setGoods] = React.useState('');
  const [quantity, setQuantity] = React.useState(1);
  const [price, setPrice] = React.useState(0);
  const [stock, setStock] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [country, setCountry] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    window.addEventListener("resize", setHtmlFontSize);
    setHtmlFontSize();
    return () => {
      window.removeEventListener("resize", setHtmlFontSize);
    }
  }, []);

  useEffect(() => {
    setTotal(Calculator.mul(price, quantity));
  }, [price, quantity]);

  const setHtmlFontSize = () => {
    var fontSize = document.documentElement.clientWidth / 375 * 16;
    if (fontSize >= 36) {
      document.documentElement.style.fontSize = fontSize + "px";
    }
  }
  const updatePrice = (goods: string) => {
    if (goods) {
      goodsObject[category].forEach((item: any) => {
        if (item.name === goods) {
          setPrice(item.price);
          setStock(item.stock);
        }
      })
    }
  }

  const clearGoods = () => {
    setGoods('');
    setPrice(0);
    setTotal(0);
    setQuantity(1);
    setCountry('');
    setStock(0);
  }

  const submitOrder = () => {
    if (!category || !goods) {
      alert('请选择商品');
      return;
    }

    if (!country) {
      alert('请选择地区');
      return;
    }

    if (!email) {
      alert('请输入邮箱');
      return;
    }
    if (!validateEmail(email)) {
      alert('请输入正确的邮箱');
      return;
    }

    setLoading(true);
    setTimeout(() => {
      window.location.href = `${payHost}?price=${total}&orderNo=${Math.random().toString(36).substr(2)}`;
    }, 1500);
  }

  return (
    <div className="App w-full h-full">
      <div className='flex bg-top p-2'>
        <img className='h-8' src="static/img/hot.png" alt="hot" />
      </div>
      <div>
        <img className='w-full' src="static/img/frame.png" alt="frame" />
      </div>
      <div className='absolute w-full top-36 p-2 '>
        <div className='bg-white rounded p-2'>
          <div className='text-sm font-bold text-left ml-2'>商户信息</div>
          <div className='flex items-center justify-between bg-gray-100 rounded p-2 m-2'>
            <div className='text-gray-600'>商户名称</div>
            <h1 className='font-bold'>出海账号平台</h1>
          </div>
          <div className='flex items-center justify-between bg-gray-100 rounded p-2 m-2'>
            <div className='text-gray-600'>TG客服</div>
            <div className='text-[15px] font-bold text-blue-500'><a href={`https://t.me/${tg}`}>{`https://t.me/${tg}`}</a></div>
          </div>
        </div>
        <div className='bg-white rounded p-2 mt-2 flex items-center'>
          <img className='h-6' src="static/img/notice.png" alt="notice" />
          <div className='font-bold'>公告</div>
          <div className='text-gray-500 mx-1'>|</div>
          <div className='marquee'>
            <div className='marquee-content font-medium'>
              {`所有商品下单前请先少量购买测试，暂停支付宝微信收款，改用USDT收款，拍下之后通过邮箱自动发货，售后请联系电报@${tg}.`}
            </div>
          </div>
        </div>
        <div className='bg-white rounded p-4 mt-2'>
          <div className='text-sm font-bold text-left'>选择商品</div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>商品分类</div>
              <div className='text-sm text-red-500'>*</div>
            </div>

            <div className='flex justify-end items-center'>
              <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none' onChange={(e) => {
                setCategory(e.target.value);
                clearGoods();
              }}>
                <option value=''>请选择商品类型</option>
                {
                  (Object.keys(goodsObject)).map((key) => {
                    return <option key={key} value={key}>{
                      key
                    }</option>
                  }
                  )}
              </select>
              <div className={`w-[10px] h-[10px] rounded-[15px] ml-1 ${category ? "bg-green-400" : "bg-red-400"}`}></div>
            </div>

          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>商品名称</div>
              <div className='text-sm text-red-500'>*</div>
            </div>

            <div className='flex justify-end items-center'>
              <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none' onChange={(e) => {
                setGoods(e.target.value);
                updatePrice(e.target.value);
              }}>
                <option value=''>请选择商品</option>
                {
                  category &&
                  (goodsObject[category]).map((item: any) => {
                    return <option key={item.id} value={item.name}>{
                      item.name
                    }</option>
                  }
                  )}
              </select>
              <div className={`w-[10px] h-[10px] rounded-[15px] ml-1 ${goods ? "bg-green-400" : "bg-red-400"}`}></div>
            </div>

          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>选择地区</div>
              <div className='text-sm text-red-500'>*</div>
            </div>

            <div className='flex justify-end items-center'>
              <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none' onChange={(e) => {
                setCountry(e.target.value);
              }}>
                <option value=''>请选择地区</option>
                {

                  category && goods && (countryArr.map((item: any) => {
                    return <option key={item.id} value={item.name}>{
                      item.name
                    }</option>
                  }
                  ))
                }
              </select>
              <div className={`w-[10px] h-[10px] rounded-[15px] ml-1 ${country ? "bg-green-400" : "bg-red-400"}`}></div>
            </div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='text-sm w-[70px] text-left'>商品单价</div>
            <div className='flex items-center'>
              <img src="static/img/subtract.png" alt="subtract" className='h-4' />
              <div className='min-w-[100px]'>{price}</div>
              <img src="static/img/add.png" alt="add" className='h-4' />
            </div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='text-sm w-[70px] text-left'>商品库存</div>
            <div className='flex items-center'>
              <img src="static/img/subtract.png" alt="subtract" className='h-4' />
              <div className='min-w-[100px]'>{stock}</div>
              <img src="static/img/add.png" alt="add" className='h-4' />
            </div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='text-sm w-[70px] text-left'>商品数量</div>
            <div className='flex items-center'>
              <img src="static/img/subtract.png" alt="subtract" className='h-4' onClick={() => {
                if (stock > 0 && quantity > 1) {
                  setQuantity(quantity - 1);
                }
              }} />
              <div className='min-w-[100px]'>{quantity}</div>
              <img src="static/img/add.png" alt="add" className='h-4' onClick={() => {
                if (stock > quantity) {
                  setQuantity(quantity + 1);
                }
              }} />
            </div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>邮箱</div>
              <div className='text-sm text-red-500 mr-2'>*</div>
            </div>
            <div className='rounded text-[14px] w-full'>
              <input type="text" className='w-full bg-gray-200 border-transparent border-none rounded text-right text-gray-600 p-2 focus:outline-none' placeholder='下单后系统自动发送卡密到该邮箱' onChange={(e) => {
                setEmail(e.target.value);
              }} />
            </div>
          </div>
        </div>
        <div className='flex rounded bg-white mt-2 p-2 justify-between items-center' >
          <div>
            <div className='text-[12px] text-left text-gray-600'>总金额</div>
            <div className='text-blue-500 text-[15px] font-medium'>{total} USDT</div>
          </div>
          <div className='bg-blue-400 text-white rounded-xl px-5 py-1 font-bold' onClick={submitOrder}>立即下单</div>
        </div>

      </div>

      {
        loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
            <div className="bg-white p-2 rounded-lg shadow-lg">
              <div className='m-2 p-2'>
                <div className='text-blue-400 font-bold'>提交中...</div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default App;
