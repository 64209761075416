export const goodsObject: any = {
  telegram电报飞机: [
    {
      id: 126,
      name: "TG飞机直登号",
      price: 0.22,
      "price-1": 0.21,
      "price-2": 0.23,
      "price-3": 0.25,
      "price-4": 0.22,
      "price-5": 0.2,
      "price-6": 0.26,
      "price-7": 0.26,
      stock: 842,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 127,
      name: "TG飞机协议号 sessions格式",
      price: 0.2,
      "price-1": 0.22,
      "price-2": 0.21,
      "price-3": 0.24,
      "price-4": 0.22,
      "price-5": 0.2,
      "price-6": 0.23,
      "price-7": 0.23,
      stock: 1237,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 128,
      name: "TG飞机耐用老号一年以上",
      price: 1.8,
      stock: 148,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 130,
      name: "TG飞机会员【一个月】",
      price: 2.59,
      stock: 544,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 440,
      name: "TG飞机会员【12个月】",
      price: 25,
      stock: 98,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 441,
      name: "TG飞机会员【6个月】",
      price: 13.8,
      stock: 85,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 442,
      name: "TG飞机会员【3个月】",
      price: 6.5,
      stock: 95,
      deletetime: null,
      outcategory_id: 37,
    },
    {
      id: 443,
      name: "Tg匿名+888号码",
      price: 39,
      stock: 114,
      deletetime: null,
      outcategory_id: 37,
    },
  ],
  WhatsApp产品: [
    {
      id: 211,
      name: "WhatsApp直登新号",
      price: 0.8,
      "price-1": 0.81,
      "price-2": 0.9,
      "price-3": 0.81,
      "price-4": 0.8,
      "price-5": 0.85,
      "price-6": 0.85,
      "price-7": 0.6,
      stock: 2001,
      deletetime: null,
      outcategory_id: 38,
    },
    {
      id: 212,
      name: "WhatsApp直登号【满月号】",
      price: 7,
      stock: 570,
      deletetime: null,
      outcategory_id: 38,
    },
    {
      id: 213,
      name: "WhatsApp直登号【3-6月】",
      price: 9,
      stock: 298,
      deletetime: null,
      outcategory_id: 38,
    },
    {
      id: 214,
      name: "WhatsApp直登号【一年以上】",
      price: 12,
      stock: 137,
      deletetime: null,
      outcategory_id: 38,
    },
    {
      id: 295,
      name: "WhatsApp可解封号",
      price: 15,
      stock: 504,
      deletetime: null,
      outcategory_id: 38,
    },
    {
      id: 333,
      name: "企业号/商业协议号/business号(接粉专用)",
      price: 0.32,
      "price-1": 0.25,
      "price-2": 0.27,
      "price-3": 0.31,
      "price-4": 0.22,
      "price-5": 0.2,
      "price-6": 0.28,
      "price-7": 0.28,
      "price-8": 0.25,
      "price-9": 0.27,
      stock: 3300,
      deletetime: null,
      outcategory_id: 38,
    },
    {
      id: 338,
      name: "whatsapp协议号(打粉专用)",
      price: 0.31,
      "price-1": 0.22,
      "price-2": 0.27,
      "price-3": 0.31,
      "price-4": 0.22,
      "price-5": 0.25,
      "price-6": 0.28,
      "price-7": 0.28,
      "price-8": 0.25,
      "price-9": 0.27,
      stock: 4401,
      deletetime: null,
      outcategory_id: 38,
    },
  ],
  TIKTOK账号: [
    {
      id: 144,
      name: "TIKTOK协议号/ios全参号/token/三绑号",
      price: 0.1,
      stock: 3285,
      deletetime: null,
      outcategory_id: 41,
    },
    {
      id: 145,
      name: "Tik Tok全新白号 手动号",
      price: 0.1,
      stock: 256,
      deletetime: null,
      outcategory_id: 41,
    },
    {
      id: 146,
      name: "TikTok百粉【活人粉丝】",
      price: 3.5,
      stock: 86,
      deletetime: null,
      outcategory_id: 41,
    },
    {
      id: 147,
      name: "TikTok千粉【活人粉丝】",
      price: 13,
      stock: 59,
      deletetime: null,
      outcategory_id: 41,
    },
    {
      id: 148,
      name: "TikTok万粉【活人粉丝】",
      price: 85,
      stock: 96,
      deletetime: null,
      outcategory_id: 41,
    },
  ],
  "Tinder火种账号 pairs omiai Match Depay交友账号": [
    {
      id: 132,
      name: "Tinder火种成品号",
      price: 15,
      stock: 103,
      deletetime: null,
      outcategory_id: 42,
    },
    {
      id: 134,
      name: "pairs成品号",
      price: 15,
      stock: 105,
      deletetime: null,
      outcategory_id: 42,
    },
    {
      id: 136,
      name: "omiai成品号",
      price: 15,
      stock: 116,
      deletetime: null,
      outcategory_id: 42,
    },
    {
      id: 138,
      name: "Match 成品号",
      price: 15,
      stock: 99,
      deletetime: null,
      outcategory_id: 42,
    },
    {
      id: 299,
      name: "badoo 成品号(蓝V认证)",
      price: 15,
      stock: 132,
      deletetime: null,
      outcategory_id: 42,
    },
  ],
  "Instagram-照片墙账号": [
    {
      id: 199,
      name: "INS-照片墙-Instagram】小白号",
      price: 0.5,
      stock: 79,
      deletetime: null,
      outcategory_id: 43,
    },
    {
      id: 200,
      name: "【IG--instgram】2013-2019年注册 带2000粉",
      price: 12,
      stock: 105,
      deletetime: null,
      outcategory_id: 43,
    },
    {
      id: 201,
      name: "【INS-照片墙-Instagram】2016-2020年注册--无粉 ",
      price: 0.8,
      stock: 46,
      deletetime: null,
      outcategory_id: 43,
    },
    {
      id: 202,
      name: "【INS-照片墙-Instagram】2016-2020年注册--带1000粉丝 ",
      price: 16,
      stock: 75,
      deletetime: null,
      outcategory_id: 43,
    },
    {
      id: 203,
      name: "【INS-照片墙-Instagram】2016-2020年注册--带5000粉丝 ",
      price: 20,
      stock: 96,
      deletetime: null,
      outcategory_id: 43,
    },
    {
      id: 204,
      name: "【INS-照片墙-Instagram】2016-2020年注册--带10000粉丝",
      price: 25,
      stock: 116,
      deletetime: null,
      outcategory_id: 43,
    },
    {
      id: 205,
      name: "【INS-照片墙-Instagram】精品号--风景号--汽车--宠物等等优质老帖子--联系客服自选",
      price: 15,
      stock: 52,
      deletetime: null,
      outcategory_id: 43,
    },
  ],
  twitter推特账号: [
    {
      id: 231,
      name: "推特小白号",
      price: 0.17,
      stock: 395,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 232,
      name: "推特老号2009-2019",
      price: 1.6,
      stock: 56,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 233,
      name: "推特老号2009-2019----带100粉",
      price: 2.2,
      stock: 88,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 234,
      name: "推特老号2009-2019注册 --带1000粉丝 ",
      price: 5,
      stock: 134,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 235,
      name: " 推特老号2009-2015注册 --带2000粉丝 ",
      price: 8.8,
      stock: 65,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 236,
      name: "推特老号2009-2019注册 --带5000粉丝",
      price: 11,
      stock: 84,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 294,
      name: "推特开发者账号",
      price: 3,
      stock: 96,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 395,
      name: "推特老号2009-2019注册 --带1万粉丝",
      price: 20,
      stock: 24,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 428,
      name: "推特老号2009-2019----带100粉（蓝V号）",
      price: 15,
      stock: 10,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 429,
      name: "推特老号2009-2019----带1000粉（蓝V号）",
      price: 30,
      stock: 8,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 430,
      name: "推特老号2009-2015注册 --带2000粉丝（蓝V号）",
      price: 50,
      stock: 5,
      deletetime: null,
      outcategory_id: 45,
    },
    {
      id: 432,
      name: "推特老号2009-2019----带5000粉（蓝V号）",
      price: 70,
      stock: 4,
      deletetime: null,
      outcategory_id: 45,
    },
  ],
  Discord不和谐账号: [
    {
      id: 207,
      name: "Discord账号（15-30天）",
      price: 0.3,
      stock: 48,
      deletetime: null,
      outcategory_id: 46,
    },
    {
      id: 208,
      name: "Discord账号（1年以上老号）",
      price: 0.8,
      stock: 35,
      deletetime: null,
      outcategory_id: 46,
    },
    {
      id: 210,
      name: "Discord账号（90天-1年）",
      price: 1,
      stock: 115,
      deletetime: null,
      outcategory_id: 46,
    },
  ],
  "苹果ID/appleid": [
    {
      id: 253,
      name: "Shadowrocket小火箭 共享",
      price: 0.1,
      stock: 51,
      deletetime: null,
      outcategory_id: 52,
    },
    {
      id: 296,
      name: "苹果ID购买独享【带密保】【未激活icloud】【1-3个月】",
      price: 0.7,
      stock: 175,
      deletetime: null,
      outcategory_id: 52,
    },
    {
      id: 433,
      name: "苹果ID购买独享【带密保】【已激活icloud】【1-3个月】",
      price: 1.4,
      stock: 254,
      deletetime: null,
      outcategory_id: 52,
    },
  ],
  skype账号: [
    {
      id: 269,
      name: "skype账号 新号",
      price: 0.2,
      stock: 112,
      deletetime: null,
      outcategory_id: 59,
    },
    {
      id: 270,
      name: "skype账号【满月号】",
      price: 0.4,
      stock: 130,
      deletetime: null,
      outcategory_id: 59,
    },
    {
      id: 271,
      name: "skype账号【3-6个月耐用号】",
      price: 0.7,
      stock: 74,
      deletetime: null,
      outcategory_id: 59,
    },
  ],
  youtube油管账户: [
    {
      id: 272,
      name: "youtube油管账户【新号】",
      price: 0.13,
      stock: 137,
      deletetime: null,
      outcategory_id: 60,
    },
    {
      id: 273,
      name: "youtube油管账户【一年老号】",
      price: 0.6,
      stock: 124,
      deletetime: null,
      outcategory_id: 60,
    },
    {
      id: 274,
      name: "youtube油管账户【3-5年老号】",
      price: 2,
      stock: 67,
      deletetime: null,
      outcategory_id: 60,
    },
    {
      id: 275,
      name: "youtube油管账户【5-10年老号】",
      price: 5,
      stock: 125,
      deletetime: null,
      outcategory_id: 60,
    },
  ],
  "paypal 贝宝账号": [
    {
      id: 283,
      name: "paypal个人号",
      price: 7,
      stock: 56,
      deletetime: null,
      outcategory_id: 65,
    },
    {
      id: 365,
      name: "paypal企业号",
      price: 13,
      stock: 22,
      deletetime: null,
      outcategory_id: 65,
    },
  ],
  Whatsapp数据提取3W: [
    {
      id: 288,
      name: "WS数据提取3W条",
      price: 1,
      stock: 49,
      deletetime: null,
      outcategory_id: 67,
    },
  ],
  "facebook/脸书/fb": [
    {
      id: 175,
      name: "FB混大小黑+商城或个人户（包8成登录率）+带cookie",
      price: 0.3,
      stock: 2875,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 314,
      name: "稳定耐用老号2013-2018",
      price: 4,
      stock: 76,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 315,
      name: "facebook新号",
      price: 0.2,
      stock: 134,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 316,
      name: "Marketpace商城号",
      price: 5,
      stock: 1567,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 317,
      name: "推荐购买---FB三解号--广告户活",
      price: 13,
      stock: 34,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 318,
      name: "FB复审解限号--可复审主页/BM --带解限恢复绿标",
      price: 10,
      stock: 23,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 319,
      name: "FB,1拖5广告户--每个50$额度",
      price: 15,
      stock: 24,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 320,
      name: "二解支付宝广告号--可支付宝充值",
      price: 10,
      stock: 32,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 321,
      name: "FB三次解限号---个人广告账户活---带广告误判恢复绿标",
      price: 8,
      stock: 20,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 322,
      name: "FB不死号-包首登无其他售后",
      price: 15,
      stock: 28,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 324,
      name: "2022年BM链接---包进入BM正常---无其他售后",
      price: 4,
      stock: 19,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 325,
      name: "2021年BM链接---包进入BM正常---无其他售后",
      price: 5,
      stock: 28,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 326,
      name: "20年-18年BM链接--包进入BM正常无售后--购买后联系客服",
      price: 6.5,
      stock: 34,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 327,
      name: "老BM可分享链接---包进入BM正常---无其他售后",
      price: 7,
      stock: 22,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 328,
      name: "Facebook---老主页---可修主页名称---人工授权后无售后",
      price: 6,
      stock: 25,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 329,
      name: "Facebook---精品主页带粉丝",
      price: 21,
      stock: 18,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 396,
      name: "Facebook有缘（24小时售后包心动包人脸）",
      price: 8,
      stock: 1287,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 410,
      name: "FB账单号（额度500）",
      price: 22,
      stock: 168,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 411,
      name: "FB账单号（额度1000）",
      price: 30,
      stock: 185,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 412,
      name: "FB账单号（额度300）",
      price: 17,
      stock: 158,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 413,
      name: "FB大黑+商城或个人户（包8成登录率）+带cookie",
      price: 0.3,
      stock: 1475,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 414,
      name: "FB小黑+商城或个人户（包8成登录率）+带cookie",
      price: 0.3,
      stock: 1300,
      deletetime: null,
      outcategory_id: 76,
    },
    {
      id: 420,
      name: "ebuy店铺号",
      price: 17,
      stock: 12,
      deletetime: null,
      outcategory_id: 76,
    },
  ],
  gcash账号: [
    {
      id: 330,
      name: "gcash实名号",
      price: 15,
      stock: 110,
      deletetime: null,
      outcategory_id: 77,
    },
    {
      id: 331,
      name: "gcash新号(不包售后)",
      price: 0.5,
      stock: 209,
      deletetime: null,
      outcategory_id: 77,
    },
  ],
  "ChatGPT/openai": [
    {
      id: 332,
      name: "ChatGPT  3.5",
      price: 1.2,
      stock: 276,
      deletetime: null,
      outcategory_id: 78,
    },
    {
      id: 403,
      name: "ChatGPT 4.0",
      price: 20,
      stock: 23,
      deletetime: null,
      outcategory_id: 78,
    },
  ],
  VKONTAKTE账号: [
    {
      id: 366,
      name: "VKONTAKTE帐户无好友",
      price: 2,
      stock: 34,
      deletetime: null,
      outcategory_id: 90,
    },
    {
      id: 367,
      name: "VKONTAKTE带50个好友和订阅者",
      price: 4,
      stock: 28,
      deletetime: null,
      outcategory_id: 90,
    },
    {
      id: 368,
      name: "VKONTAKTE带300个好友和订阅者",
      price: 10,
      stock: 26,
      deletetime: null,
      outcategory_id: 90,
    },
    {
      id: 369,
      name: "VKONTAKTE带1500个好友和订阅者",
      price: 20,
      stock: 32,
      deletetime: null,
      outcategory_id: 90,
    },
    {
      id: 369,
      name: "VKONTAKTE广告户开户",
      price: 15,
      stock: 122,
      deletetime: null,
      outcategory_id: 90,
    },
    {
      id: 369,
      name: "VKONTAKTE广告户充值$100",
      price: 102,
      stock: 320,
      deletetime: null,
      outcategory_id: 90,
    },
  ],
  巴豆badoo: [
    {
      id: 370,
      name: "badoo白号",
      price: 0.5,
      stock: 46,
      deletetime: null,
      outcategory_id: 91,
    },
    {
      id: 371,
      name: "badoo 成品号(蓝V认证)",
      price: 12,
      stock: 23,
      deletetime: null,
      outcategory_id: 91,
    },
  ],
  wish账号: [
    {
      id: 373,
      name: "wish",
      price: 0.5,
      stock: 45,
      deletetime: null,
      outcategory_id: 93,
    },
    {
      id: 374,
      name: "wish商家号",
      price: 17,
      stock: 24,
      deletetime: null,
      outcategory_id: 93,
    },
  ],
  potato土豆: [
    {
      id: 377,
      name: "白号",
      price: 0.5,
      stock: 55,
      deletetime: null,
      outcategory_id: 94,
    },
    {
      id: 378,
      name: "1-3个月号",
      price: 3,
      stock: 36,
      deletetime: null,
      outcategory_id: 94,
    },
  ],
  betcity账号: [
    {
      id: 381,
      name: "betcity白号",
      price: 0.5,
      stock: 35,
      deletetime: null,
      outcategory_id: 96,
    },
    {
      id: 382,
      name: "betcity实名认证号",
      price: 12,
      stock: 23,
      deletetime: null,
      outcategory_id: 96,
    },
  ],
  欧易okx: [
    {
      id: 384,
      name: "欧易白号",
      price: 0.4,
      stock: 75,
      deletetime: null,
      outcategory_id: 98,
    },
    {
      id: 385,
      name: "欧易认证号",
      price: 15,
      stock: 17,
      deletetime: null,
      outcategory_id: 98,
    },
  ],
  币安Binance: [
    {
      id: 584,
      name: "币安白号",
      price: 0.4,
      stock: 95,
      deletetime: null,
      outcategory_id: 98,
    },
    {
      id: 385,
      name: "币安认证号",
      price: 15,
      stock: 37,
      deletetime: null,
      outcategory_id: 98,
    },
  ],
  reddit账号: [
    {
      id: 387,
      name: "reddit账号(15-90天)",
      price: 1,
      stock: 89,
      deletetime: null,
      outcategory_id: 100,
    },
    {
      id: 438,
      name: "reddit账号(1年-2年)",
      price: 3,
      stock: 143,
      deletetime: null,
      outcategory_id: 100,
    },
    {
      id: 439,
      name: "reddit账号(90天-1年)",
      price: 2,
      stock: 142,
      deletetime: null,
      outcategory_id: 100,
    },
  ],
  freelancer账号: [
    {
      id: 393,
      name: "freelancer账号",
      price: 1.5,
      stock: 24,
      deletetime: null,
      outcategory_id: 105,
    },
  ],
  "fb有缘号/facebook有缘号": [
    {
      id: 394,
      name: "Facebook有缘（24小时售后包心动包人脸）",
      price: 8,
      stock: 1287,
      deletetime: null,
      outcategory_id: 106,
    },
  ],
  steam账号: [
    {
      id: 459,
      name: "steam账号",
      price: 1,
      stock: 145,
      deletetime: null,
      outcategory_id: 138,
    },
  ],
  onlyfans账号: [
    {
      id: 463,
      name: "onlyfans账号",
      price: 1.5,
      stock: 145,
      deletetime: null,
      outcategory_id: 142,
    },
  ],
  grindr账号: [
    {
      id: 464,
      name: "grindr账号",
      price: 2,
      stock: 145,
      deletetime: null,
      outcategory_id: 143,
    },
  ],
  Bybit账号: [
    {
      id: 466,
      name: "Bybit账号",
      price: 10,
      stock: 179,
      deletetime: null,
      outcategory_id: 144,
    },
  ],
  "其他业务/补差价": [
    {
      id: 465,
      name: "其他业务/差价专用",
      price: 1,
      stock: 845,
      deletetime: null,
      outcategory_id: 142,
    },
  ],
};
